import { __awaiter } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import { useHistory, useLocation } from 'react-router-dom';
import { useFetchInitialUser, IndexLayoutContent, IndexLayoutHeaderLink, IndexLayoutOldTitle, routes } from '@sketch/modules-common';
import { Link } from '@sketch/components';
import { Wrapper, Subtitle, FormContainer, LinkDescription } from './RecoveryCodeView.styles.js';
import './RecoveryCodeForm/index.js';
import RecoveryCodeForm from './RecoveryCodeForm/RecoveryCodeForm.js';

const RecoveryCodeView = (props) => {
    const { HeaderPortal } = props;
    const history = useHistory();
    const location = useLocation();
    const fetchInitialUser = useFetchInitialUser();
    return (jsxs(IndexLayoutContent, Object.assign({ center: "horizontal", marginTop: true, paddingHorizontal: true }, { children: [jsx(HeaderPortal, { children: jsx(IndexLayoutHeaderLink, { headerLink: "back-sign-in" }) }), jsxs(Wrapper, { children: [jsx(IndexLayoutOldTitle, { children: "Enter Recovery Code" }), jsx(Subtitle, { children: "Can\u2019t access your authenticator app? Enter a recovery code instead." }), jsx(FormContainer, { children: jsx(RecoveryCodeForm, { onCompleted: () => __awaiter(void 0, void 0, void 0, function* () {
                                var _a;
                                // Get the user profile for the first time now, since <UserContext /> (App.tsx)
                                // can't do it because the user is not signed in until now
                                yield fetchInitialUser();
                                history.push(((_a = location.state) === null || _a === void 0 ? void 0 : _a.from) || routes.ENTRY.create({}));
                            }) }) }), jsxs(LinkDescription, { children: ["Authenticator app working again?", ' ', jsx(Link, Object.assign({ to: {
                                    pathname: routes.VERIFICATION_CODE.create({}),
                                    state: {
                                        mfaToken: location.state.mfaToken,
                                        from: location.state.from,
                                    },
                                }, variant: "tertiary", isUnderlined: true }, { children: "Enter verification code" }))] }), jsxs(LinkDescription, { children: ["Lost your recovery codes?", ' ', jsx(Link, Object.assign({ external: true, variant: "tertiary", isUnderlined: true, href: "https://www.sketch.com/support/contact/" }, { children: "Contact us" }))] })] })] })));
};

export { RecoveryCodeView as default };

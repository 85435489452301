import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { PageLayout, LoadingState } from '@sketch/components';
import '../Footer/index.js';
import '../EmptyHeader/index.js';
import '../../utils/index.js';
import { useRefresh24 } from '../../utils/useRefresh24.js';
import { EmptyHeader } from '../EmptyHeader/EmptyHeader.js';
import { Footer } from '../Footer/Footer.js';

const LoadingPage = (_a) => {
    var { hideFooter } = _a, props = __rest(_a, ["hideFooter"]);
    // TODO: Remove FF "ui-refresh-24" when its released
    // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
    const isRefreshedUi = useRefresh24();
    return (jsx(PageLayout, Object.assign({ isRefreshedUi: isRefreshedUi, header: jsx(EmptyHeader, { hideLogo: true, isDocumentView: true }), footer: !hideFooter && jsx(Footer, {}) }, { children: jsx(LoadingState, Object.assign({}, props)) })));
};

export { LoadingPage };

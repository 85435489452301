import { jsx } from 'react/jsx-runtime';
import '../IndexLayoutOld/index.js';
import { useFlag } from '../../flags.js';
import '../IndexLayout/index.js';
import { IndexLayoutLoading } from '../IndexLayout/IndexLayoutLoading.js';
import { IndexLayoutLoading as IndexLayoutLoading$1 } from '../IndexLayoutOld/IndexLayoutLoading.js';

const WorkspaceSubscribeLoadingPage = () => {
    const isRefreshUi = useFlag('ui-refresh-24');
    const Component = isRefreshUi ? IndexLayoutLoading : IndexLayoutLoading$1;
    return jsx(Component, {});
};

export { WorkspaceSubscribeLoadingPage };

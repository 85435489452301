import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { useLocation } from 'react-router-dom';
import '../../routes/index.js';
import MacApp1x from '@sketch/icons/mac-app-logo@1x.png';
import MacApp2x from '@sketch/icons/mac-app-logo@2x.png';
import { useForDesktop, LinkButton, Navbar, Text, Link } from '@sketch/components';
import { MarketingWebsiteBackToWorkspaceButton, StyledAvatar, MarketingWebsiteAnchorButton, MacAppLogo, Separator, Header, LogoWrapper, Logo } from './IndexLayout.styles.js';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import '../../libs/index.js';
import { useScrollTop } from '@sketch/utils';
import { routes } from '../../routes/routes.js';
import { useUserProfile } from '../../libs/authentication/useUserProfile.js';

const headerLinkProps = {
    'sign-up': {
        link: routes.SIGN_UP.create({}),
        text: 'New to Sketch?',
        buttonCopy: 'Create an Account',
    },
    'sign-in': {
        link: routes.SIGN_IN.create({}),
        text: 'Already have an account?',
        buttonCopy: 'Sign In',
    },
    entry: {
        link: routes.ENTRY.create({}),
        text: null,
        buttonCopy: 'Back',
    },
    'workspace-settings': {
        link: routes.WORKSPACE_AGNOSTIC_SETTINGS_PEOPLE.create({}),
        text: null,
        buttonCopy: 'Back to Workspace',
    },
    'back-sign-in': {
        link: routes.SIGN_IN.create({}),
        text: null,
        buttonCopy: 'Back to Sign In',
    },
};
const HeaderLink = ({ headerLink, withDownload, }) => {
    var _a;
    const { state } = useLocation();
    const { data } = useUserProfile(true);
    const isDesktop = useForDesktop();
    const hasBackToWorkspaceButton = headerLink === 'workspace-settings';
    const hasActions = headerLink !== 'none';
    if (!hasActions) {
        return null;
    }
    const headerLinkButton = hasBackToWorkspaceButton ? (jsxs(MarketingWebsiteBackToWorkspaceButton, Object.assign({ variant: "secondary", size: "40", to: {
            pathname: headerLinkProps[headerLink].link,
            state,
        } }, { children: [jsx(StyledAvatar, { src: (_a = data === null || data === void 0 ? void 0 : data.me.avatar) === null || _a === void 0 ? void 0 : _a.small, size: "32px", name: (data === null || data === void 0 ? void 0 : data.me.name) || (data === null || data === void 0 ? void 0 : data.me.email) || '?' }), headerLinkProps[headerLink].buttonCopy] }))) : (headerLink && (jsx(LinkButton, Object.assign({ variant: "secondary", size: "32", to: {
            pathname: headerLinkProps[headerLink].link,
            state,
        } }, { children: headerLinkProps[headerLink].buttonCopy }))));
    return (jsxs(Navbar.Section, Object.assign({ align: "end", ml: 2 }, { children: [withDownload && isDesktop && (jsxs(Fragment, { children: [jsxs(MarketingWebsiteAnchorButton, Object.assign({ href: `${SKETCH_WEBSITE}/downloads/mac/`, target: "_blank" }, { children: [jsx(MacAppLogo, { srcSet: `${MacApp1x}, ${MacApp2x} 2x`, src: MacApp1x, alt: "Sketch Mac app icon" }), "Download"] })), hasBackToWorkspaceButton && jsx(Separator, {})] })), headerLink && headerLinkProps[headerLink].text && (jsx(Text, Object.assign({ textStyle: "copy.secondary.standard.D", mr: 4 }, { children: headerLinkProps[headerLink].text }))), headerLinkButton] })));
};
const IndexLayoutHeader = ({ headerLink, disableLogoLink, withDownload, }) => {
    const { isScrollTop } = useScrollTop();
    return (jsxs(Header, Object.assign({ "data-scrolltop": isScrollTop }, { children: [jsx(LogoWrapper, Object.assign({ "data-testid": "sketch-logo" }, { children: disableLogoLink ? (jsx(Logo, {})) : (jsx(Link, Object.assign({ to: routes.ENTRY.create({}) }, { children: jsx(Logo, {}) }))) })), jsx(HeaderLink, { headerLink: headerLink, withDownload: withDownload })] })));
};

export { HeaderLink, IndexLayoutHeader };

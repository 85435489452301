import React, { useRef } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import {
  DynamicLoadingPage,
  GenericErrorView,
  routes,
  RouteProps,
  useFlag,
} from '@sketch/modules-common'

import { removeItem, useNagivationScrollTop } from '@sketch/utils'

import { localStorageKeys } from '@sketch/constants'

import { useIsProfileCreated } from 'modules/community/hooks'
import {
  useWorkspaceSettings,
  useRefetchWorkspaceSettingsOnRouteChange,
  useGetWorkspaces,
} from 'modules/workspace/operations'

import { BillingStatusBanners } from 'modules/workspace/components'

import { PrivateWorkspaceRouteExtraProps } from 'modules/workspace/containers/PrivateWorkspaceRoute'

import {
  WorkspaceSettingsBilling,
  WorkspaceSettingsSso,
} from 'modules/workspace/pages'

import { PanelWrapper } from './WorkspaceSettingsView.styles'

// Pages
import { PersonalWorkspaceSettingsPeople } from '../../pages/WorkspaceSettingsPeople'
import { WorkspaceSettingsProfile } from '../../pages/WorkspaceSettingsProfile'
import WorkspaceSettingsPermissionGroups from '../../pages/WorkspaceSettingsPermissionGroups'
import WorkspaceSettingsLayout from 'modules/workspace/components/WorkspaceSettingsLayout'
import { WorkspaceSettingsPeopleContextProvider } from './WorkspaceSettingsPeopleContext'
import {
  useGetWorkspaceMembershipsQuery,
  useGetWorkspaceStorageDetailsQuery,
} from '@sketch/gql-types'
import {
  workspaceSettingsPermissions,
  useCanSubscribeViaStripe,
} from 'modules/workspace/utils'
import { CanRenderOptions } from 'modules/workspace/types'

interface WorkspaceSettingsProps
  extends RouteProps<'WORKSPACE_SETTINGS'>,
    PrivateWorkspaceRouteExtraProps {}

const WorkspaceSettingsPeople = React.lazy(
  () => import('../../pages/WorkspaceSettingsPeople')
)
const WorkspaceSettingsGeneral = React.lazy(
  () => import('../../pages/WorkspaceSettingsGeneral')
)

/**
 * WorkspaceSettings
 *
 * This is the base component for the Workspace settings
 * It renders
 * - General Section
 * - Billing Section
 * - SSO Section
 */
const WorkspaceSettingsView: React.FC<WorkspaceSettingsProps> = props => {
  // TODO: remove this flag when feature is shipped
  // https://github.com/sketch-hq/Cloud/issues/17947
  const isWorkspaceStorageFFEnabled = useFlag('workspace-storage')

  // TODO: Remove FF "ui-refresh-24" when its released
  // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
  const isRefreshedUi = useFlag('ui-refresh-24')

  const elementToScrollTop = useRef<HTMLElement | null>(null)

  const { workspace } = props
  const { identifier, avatar, name } = workspace
  const workspaceId = workspace.identifier
  const isAdmin = workspace.userRole === 'ADMIN'

  const { isProfileCreated } = useIsProfileCreated(workspace.identifier)

  const {
    workspaces,
    loading: workspacesLoading,
    error: hasWorkspacesError,
  } = useGetWorkspaces()

  const {
    data: workspaceSettingsData,
    loading: isWorkspaceSettingsLoading,
    updating: isUpdatingWorkspaceSettings,
    error: hasWorkspaceSettingsError,
    refetch,
  } = useWorkspaceSettings(workspace)

  useRefetchWorkspaceSettingsOnRouteChange(refetch, workspaceId)

  const { workspace: workspaceSettings, customer, sso } =
    workspaceSettingsData || {}

  const { source, customerProvider } = useCanSubscribeViaStripe(
    identifier,
    customer?.id
  )

  const {
    hideNavigation,
    isEducationWorkspace,
    isBusinessWorkspace,
    isFinance,
    isIOS,
    isPartner,
    isPersonalWorkspace,
    isGuest,
    showUpsell,
    showWorkspaceSettingsBilling,
    showWorkspaceSettingsGeneral,
    showWorkspaceSettingsSSO,
    showWorkspaceSettingsPeople,
    showWorkspacePermissionGroup,
    showWorkspaceSettingsProfile,
  } = workspaceSettingsPermissions({
    workspace,
    customerProvider,
    settings: workspaceSettings,
    customer,
    source,
    isProfileCreated,
  })

  // Menu permissions for Workspace Layout
  const canRender: CanRenderOptions = {
    generalMenu: showWorkspaceSettingsGeneral,
    billingMenu: showWorkspaceSettingsBilling,
    permissionGroups: showWorkspacePermissionGroup,
    SSOMenu: showWorkspaceSettingsSSO,
    peopleMenu: showWorkspaceSettingsPeople,
    profileMenu: showWorkspaceSettingsProfile,
  }

  // Check for Sketch Partner on the workspace
  const {
    data: sketchPartnerData,
    loading: isLoadingPartnerMembership,
  } = useGetWorkspaceMembershipsQuery({
    variables: {
      workspaceId: workspaceId,
      role: 'PARTNER',
    },
    skip: isPartner,
  })

  const {
    data: storageData,
    loading: isLoadingStorage,
    error: hasStorageError,
  } = useGetWorkspaceStorageDetailsQuery({
    variables: {
      identifier: workspaceId,
    },
    fetchPolicy: 'network-only',
    skip: !isWorkspaceStorageFFEnabled || !canRender.billingMenu,
  })

  const loading =
    workspacesLoading ||
    isWorkspaceSettingsLoading ||
    isLoadingPartnerMembership ||
    isLoadingStorage

  const error =
    hasWorkspacesError || hasWorkspaceSettingsError || hasStorageError

  useNagivationScrollTop(elementToScrollTop.current)

  if (isGuest) {
    return <Redirect to={routes.WORKSPACE_SHARES.create({ workspaceId })} />
  }

  /**
   * Because workspace and workspaceSettings are fetched in two different queries,
   * after upgrading a legacy workspace, the user can be in a situation where both
   * are out of sync in the cache. "workspace" already says that the workspace has been upgraded
   * but the settings is missing info we are expecting for upgraded workspaces (billing for example)
   * and we need to wait for it to be updated in the cache.
   * https://github.com/sketch-hq/Cloud/issues/8658
   */
  const isUpdatingWorkspaceSettingsInCacheAfterUpgrade =
    workspaceSettings && workspace.type !== workspaceSettings.type

  /**
   * Updates should not show the page spinner unless it's an important one that changes
   * the structure of the page (legacy workspace upgrade)
   */
  if (
    loading &&
    (!isUpdatingWorkspaceSettings ||
      isUpdatingWorkspaceSettingsInCacheAfterUpgrade)
  ) {
    return <DynamicLoadingPage />
  }

  if (error || !workspaceSettings || !workspaces) {
    // This should be taken care by the PrivateWorkspaceRoute already
    // However, due to a different issue: https://github.com/sketch-hq/Cloud/issues/15006
    // The workspace which is invalid might bleed to a deeper component
    //
    // Remove this check when #15006 will be solved:
    removeItem(localStorageKeys.lastWorkspaceIdKey)
    return (
      <GenericErrorView
        error={error}
        // Remove this when #15006 will be solved:
        onClick={() => (window.location.href = '/c')}
      />
    )
  }

  if (isPersonalWorkspace && !workspace.userIsOwner) {
    return (
      <GenericErrorView errorTitle="Only the owner of this workspace can see its settings" />
    )
  }

  const {
    billing,
    identifier: customerId,
    ssoEnabled: ssoActive,
    ssoShortName,
  } = workspaceSettings.customer ?? {}

  const hasSSOFeature = workspaceSettings.features.ssoEnabled
  const hasUnlimitedStorage = !!workspace.features.unlimitedStorageEnabled

  const { status, trialEnd, seats } = billing ?? {}

  const avatarSrc = avatar?.large || undefined
  const subscriptionEnd = billing?.subscriptionEnd || undefined

  const currentPlan = customer?.subscription?.currentPlan || undefined
  const nextBillingCycleDate = customer?.subscription?.renewalDate || undefined
  const pendingScaToken = customer?.subscription?.pendingScaToken as
    | string
    | undefined

  const projectedCost = customer?.billingSummary?.nextBillingCycle?.totalAmount
  const credits = customer?.credits
  const paymentDetails = customer?.paymentDetails

  const workspacePartner =
    sketchPartnerData?.workspace.memberships.list?.entries?.[0]

  return (
    <WorkspaceSettingsLayout
      ref={elementToScrollTop}
      workspace={workspace}
      canUseSso={hasSSOFeature}
      hideNavbar={isRefreshedUi || hideNavigation}
      canRender={canRender}
    >
      {status && (
        <BillingStatusBanners
          workspaceId={workspaceId}
          billingStatus={status}
          cancelPeriodEnd={subscriptionEnd}
          isUserAdmin={isAdmin}
          isEducationWorkspace={isEducationWorkspace}
        />
      )}
      <PanelWrapper>
        <Switch>
          <Route
            path={routes.WORKSPACE_SETTINGS_PEOPLE.template()}
            render={() => {
              if (isPersonalWorkspace) {
                /* There's already a FF inside there soo no need to touch it */
                return <PersonalWorkspaceSettingsPeople name={name} />
              }

              return (
                <WorkspaceSettingsPeopleContextProvider>
                  <WorkspaceSettingsPeople
                    workspaceId={identifier}
                    name={name}
                    avatar={avatarSrc}
                    role={workspace.userRole}
                    billingStatus={status!}
                    subscriptionEnd={subscriptionEnd}
                    seats={seats!}
                    /* Exclusive Administrative Props */
                    isBusinessWorkspace={isBusinessWorkspace}
                    customerId={customerId!}
                    // These are optional because this page is common for both
                    // members and admins. This information is only available
                    // in PaymentQL which is queryable by admins only
                    currentPlan={currentPlan}
                    nextBillingCycleDate={nextBillingCycleDate}
                    paymentDetails={paymentDetails}
                    isSsoActive={ssoActive!}
                    isEducationWorkspace={isEducationWorkspace}
                    isIOS={isIOS}
                    showUpsell={showUpsell}
                    userDirectoryEnabled={
                      workspaceSettings.features.userDirectoryEnabled
                    }
                    showWorkspacePermissionGroup={showWorkspacePermissionGroup}
                    source={source}
                  />
                </WorkspaceSettingsPeopleContextProvider>
              )
            }}
            exact
          />
          {showWorkspaceSettingsGeneral && (
            <Route
              path={routes.WORKSPACE_SETTINGS_SETTINGS.template()}
              render={() => (
                <WorkspaceSettingsGeneral
                  workspaceId={identifier}
                  name={name}
                  avatar={avatarSrc}
                  userIsOwner={workspace.userIsOwner}
                  areUploadsRestricted={
                    workspaceSettings.uploadsRestrictedFor !== 'NONE'
                  }
                  arePublicLinksRestricted={
                    workspaceSettings.features.restrictedPublicLinks
                  }
                  canUseRestrictedUploads={
                    workspaceSettings.canUseRestrictedUploads
                  }
                />
              )}
              exact
            />
          )}
          {showWorkspacePermissionGroup && (
            <Route
              path={routes.WORKSPACE_SETTINGS_PERMISSION_GROUPS.template()}
              render={() => (
                <WorkspaceSettingsPermissionGroups workspace={workspace} />
              )}
            />
          )}
          {showWorkspaceSettingsBilling && (
            <Route
              path={routes.WORKSPACE_SETTINGS_BILLING.template()}
              render={() => (
                <WorkspaceSettingsBilling
                  onWorkspaceSettingRefresh={refetch}
                  workspaceId={identifier}
                  workspaceName={name}
                  subscriptionStatus={status!}
                  subscriptionEnd={subscriptionEnd}
                  seats={seats!}
                  isFinance={isFinance}
                  isEducationWorkspace={isEducationWorkspace}
                  isSsoActive={ssoActive!}
                  /* Exclusive Administrative Props */
                  credits={credits!}
                  customerId={customerId!}
                  currentPlan={currentPlan}
                  pendingSCAToken={pendingScaToken}
                  nextBillingCycleDate={nextBillingCycleDate}
                  billingDetails={customer!.billingDetails!}
                  paymentDetails={paymentDetails}
                  projectedCost={projectedCost}
                  trialEnd={trialEnd!}
                  isPartner={isPartner}
                  showUpsell={showUpsell}
                  partner={workspacePartner ? workspacePartner : null}
                  // Storage
                  storageDetails={storageData}
                  hasUnlimitedStorage={hasUnlimitedStorage}
                  isAdmin={isAdmin}
                />
              )}
              exact
            />
          )}
          {showWorkspaceSettingsSSO && (
            <Route
              path={routes.WORKSPACE_SETTINGS_SSO.template()}
              render={() => (
                <WorkspaceSettingsSso
                  customerId={customerId!}
                  workspaceId={identifier}
                  ssoShortName={ssoShortName || undefined}
                  isSsoActive={ssoActive!}
                  isScimEnabled={workspaceSettings.scimEnabled}
                  config={sso?.ssoConfig || undefined}
                  showUpsell={showUpsell}
                />
              )}
              exact
            />
          )}
          {isProfileCreated && isAdmin && (
            <Route
              path={routes.WORKSPACE_SETTINGS_PROFILE.template()}
              render={() => (
                <WorkspaceSettingsProfile workspaceIdentifier={workspaceId} />
              )}
              exact
            />
          )}
          <Redirect
            from={routes.WORKSPACE_SETTINGS.template()}
            to={routes.WORKSPACE_SETTINGS_PEOPLE.create({
              workspaceId,
            })}
          />
        </Switch>
      </PanelWrapper>
    </WorkspaceSettingsLayout>
  )
}

export default WorkspaceSettingsView

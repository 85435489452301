import React from 'react'
import * as yup from 'yup'
import { Formik, FormikHelpers } from 'formik'

import { ReactComponent as MailIcon } from '@sketch/icons/mail-envelope-24'

import { Tooltip } from '@sketch/components'
import { RoleDropdown } from '../RoleDropdown'

import {
  InputWrapper,
  StyledButton,
  StyledForm,
  StyledFormField,
  StyledInput,
  Wrapper,
} from './MemberInvite.styles'
import { castError } from '@sketch/utils'
import { useFlag } from '@sketch/modules-common'

interface FormValues {
  email: string
  isEditor: boolean
  isAdmin: boolean
}
type FormActions = FormikHelpers<FormValues>

interface MemberInviteProps {
  onInvite: (member: FormValues) => Promise<void>
  isDisabled?: boolean
  isEditorDisabled?: boolean
  isIOS?: boolean
  isPartner?: boolean
  inviteeLimit: number
  isInviteesLimitExceeded: boolean
}

// Formik Validation
const VALIDATION_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .email('Must be a valid email address')
    .trim()
    .lowercase()
    .required('Email is a required field'),
  isEditor: yup.bool(),
  isAdmin: yup.bool(),
})

/**
 * MemberInvite
 *
 * Renders a text input, a dropdown and a invite button
 * that allows adding members to a workspace
 */
export const MemberInvite: React.FC<MemberInviteProps> = ({
  onInvite,
  isDisabled,
  isEditorDisabled,
  isIOS,
  isPartner,
  inviteeLimit,
  isInviteesLimitExceeded,
}) => {
  // TODO: Remove FF "ui-refresh-24" when its released
  // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
  const isRefreshedUi = useFlag('ui-refresh-24')

  const initialValues = {
    email: '',
    isEditor: isEditorDisabled ? false : true,
    isAdmin: false,
  }

  const handleSubmit = async (values: FormValues, actions: FormActions) => {
    /**
     *  Formik don't accept any changes from Yup, only validations
     *  that's why we need to enforce it when submitting
     */
    const trimmedValues = VALIDATION_SCHEMA.cast({
      ...values,
      isEditor: isEditorDisabled ? false : values.isEditor,
    })

    try {
      // This is already being validated, but since yup.cast can return
      // undefined we need this extra check.
      const { email = '', isAdmin, isEditor } = trimmedValues
      await onInvite({ email, isAdmin: isAdmin!, isEditor: isEditor! })
    } catch (e) {
      const err = castError(e)
      actions.setFieldError('email', err.message)
      return
    }

    actions.setSubmitting(false)
    actions.resetForm({
      values: {
        email: '',
        isAdmin: false,
        isEditor: isEditorDisabled ? false : values.isEditor,
      },
    })
  }

  const handleSubmitPartner = async (
    values: FormValues,
    actions: FormActions
  ) => {
    // We shouldn't allow the partner as owner of the workspace

    /**
     *  Formik don't accept any changes from Yup, only validations
     *  that's why we need to enforce it when submitting
     */
    const trimmedValues = VALIDATION_SCHEMA.cast({
      ...values,
      isEditor: true,
      isAdmin: true,
    })

    try {
      // This is already being validated, but since yup.cast can return
      // undefined we need this extra check.
      const { email = '' } = trimmedValues
      await onInvite({ email, isAdmin: true, isEditor: true })
    } catch (e) {
      const err = castError(e)
      actions.setFieldError('email', err.message)
      return
    }

    actions.setSubmitting(false)
    actions.resetForm({
      values: {
        email: '',
        isAdmin: false,
        isEditor: isEditorDisabled ? false : values.isEditor,
      },
    })
  }

  const helpText = `Invite up to ${inviteeLimit} Members at a time. To invite more, create another round of invitations.`
  const tooltipText = `You can only invite ${inviteeLimit} members at a time`

  return (
    <Wrapper data-testid="member-invite" $isRefreshedUi={isRefreshedUi}>
      <Formik
        initialValues={initialValues}
        onSubmit={!isPartner ? handleSubmit : handleSubmitPartner}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({
          touched,
          values,
          errors,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
          submitForm,
        }) => {
          const isLoading = isSubmitting
          const isFormDisabled =
            values?.email === '' || isDisabled || isInviteesLimitExceeded

          return (
            <StyledForm>
              <InputWrapper>
                <StyledFormField
                  aria-label="Email"
                  name="email"
                  errorText={touched.email ? errors.email : undefined}
                  help={helpText}
                >
                  <StyledInput
                    name="email"
                    type="text"
                    icon={<MailIcon width="24px" height="24px" />}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isDisabled}
                    placeholder="Enter an email address"
                    $withRightPadding={!isIOS}
                    small
                    onKeyDown={keyEvent => {
                      // Prevent Enter key submitting the whole form.
                      // see https://github.com/sketch-hq/Cloud/issues/8318
                      if (
                        keyEvent.key === 'Enter' ||
                        (keyEvent.charCode || keyEvent.keyCode) === 13
                      ) {
                        keyEvent.preventDefault()
                        submitForm()
                      }
                    }}
                  />
                </StyledFormField>
                {!isIOS && !isPartner && (
                  <RoleDropdown
                    onEditor={() => {
                      setFieldValue('isEditor', true)
                    }}
                    onViewer={() => {
                      setFieldValue('isEditor', false)
                    }}
                    onToggleAdmin={() => {
                      setFieldValue('isAdmin', !values.isAdmin)
                    }}
                    isEditor={isEditorDisabled ? false : values.isEditor}
                    isAdmin={values.isAdmin}
                    isDisabled={isDisabled}
                    isEditorDisabled={isEditorDisabled}
                    showAdminToggle
                    isEmbedded
                  />
                )}
              </InputWrapper>

              <Tooltip
                placement="bottom"
                content={tooltipText}
                disabled={!isInviteesLimitExceeded}
                contentStyle={{
                  // Make sure the copy fits in a single line
                  minWidth: 'fit-content',
                }}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      // Fine tunning the tooltip position under the button
                      offset: [0, -30],
                    },
                  },
                ]}
              >
                <StyledButton
                  type="button"
                  variant={!isPartner ? 'primary' : 'secondary'}
                  disabled={isFormDisabled}
                  loading={isLoading}
                  onClick={submitForm}
                  size="small"
                >
                  <>{!isPartner ? 'Add to List' : 'Add'}</>
                </StyledButton>
              </Tooltip>
            </StyledForm>
          )
        }}
      </Formik>
    </Wrapper>
  )
}

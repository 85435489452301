import React, { useState, useEffect } from 'react'
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom'

import {
  useForTablet,
  useForBigScreen,
  SidebarLayout,
} from '@sketch/components'
import {
  isCwvRouteOnly,
  useBrowserSettings,
  useRefresh24,
} from '@sketch/modules-common'
import {
  useLayoutOverrideProps,
  useCreateLayoutPortal,
  useStableHandler,
} from '@sketch/utils'

import ShareWorkspaceSidebar from '../ShareWorkspaceSidebar/ShareWorkspaceSidebar'
import { LayoutHeader } from './LayoutHeader'
import { HeaderPortalContainer } from './DocumentSidebarLayout.styles'
import {
  DocumentSidebarLayoutExtraProps,
  LayoutLayoutOverridableProps,
  SidebarLayoutChildrenProps,
} from './types'
import {
  PublicWorkspaceFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

interface RenderPropChildren {
  render: (props: DocumentSidebarLayoutExtraProps) => React.ReactElement
}

interface ComponentChildren {
  component: React.ComponentType<DocumentSidebarLayoutExtraProps>
}

type DocumentSidebarLayoutRouteProps = OneOf<
  RenderPropChildren,
  ComponentChildren
>

type DocumentSidebarLayoutProps = DocumentSidebarLayoutRouteProps & {
  title?: string
  darkBackground?: boolean
  hideBackButton?: boolean
  sidebarOnTopOfHeader?: boolean
  workspace: WorkspaceMinimalFragment | PublicWorkspaceFragment
}

interface LocationState {
  toggleSidebarRight?: boolean
}

export const DocumentSidebarLayout = ({
  ...props
}: DocumentSidebarLayoutProps) => {
  const {
    render,
    component: Component,
    title: initialTitle,
    hideBackButton: initialHideBackButton,
    sidebarOnTopOfHeader: initialSidebarOnTopOfHeader = true,
    workspace,
    ...rest
  } = props

  const isRefreshedUi = useRefresh24()

  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false)
  const isTabletAndBigger = useForTablet()
  const isDesktopAndBigger = useForBigScreen()

  const location = useLocation<LocationState>()
  const history = useHistory()

  const updateLocationState = useStableHandler(
    (newLocationState: LocationState) => {
      history.replace({
        ...location,
        state: {
          ...location.state,
          ...newLocationState,
        },
      })
    }
  )

  const toggleMobileSidebar = () => {
    // This state flag will help us to know if the user actively
    // toggled the sidebar (we use it only for mobile view)
    updateLocationState({ toggleSidebarRight: true })

    setIsMobileSidebarOpen(isOpen => !isOpen)
  }

  const [data, updateBrowserSettings] = useBrowserSettings()
  const isSidebarRightOpen = data?.sidebarRightOpen

  // For Components Web View pages on tablet we hide the sidebar because we
  // prioritise the left menu sidebar
  const { path } = useRouteMatch()
  const shouldHideRightSidebar =
    isTabletAndBigger && !isDesktopAndBigger && isCwvRouteOnly(path)

  const toggleSidebarRight = () => {
    updateBrowserSettings({
      sidebarRightOpen: !isSidebarRightOpen,
    })
  }

  useEffect(() => {
    if (isSidebarRightOpen && shouldHideRightSidebar) {
      toggleSidebarRight?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isOpen = isTabletAndBigger ? isSidebarRightOpen : isMobileSidebarOpen
  const toggle = isTabletAndBigger ? toggleSidebarRight : toggleMobileSidebar

  // Layout hooks
  const {
    getOverriddenProps,
    useOverrideLayoutProps,
  } = useLayoutOverrideProps<LayoutLayoutOverridableProps>()
  const {
    title,
    hideHeaderBadge,
    hideBackButton,
    darkBackground,
    sidebarOnTopOfHeader,
  } = getOverriddenProps({
    title: initialTitle,
    hideBackButton: initialHideBackButton,
    sidebarOnTopOfHeader: initialSidebarOnTopOfHeader,
  }) as LayoutLayoutOverridableProps

  const [HeaderPortal, setHeaderPortalContainerRef] = useCreateLayoutPortal()
  const [
    SidebarRightPortal,
    setSidebarRightPortalContainerRef,
  ] = useCreateLayoutPortal()
  const [FooterPortal, setFooterPortalContainerRef] = useCreateLayoutPortal()

  const childrenProps = {
    HeaderPortal,
    SidebarRightPortal,
    FooterPortal,
    useOverrideLayoutProps,
  }

  return (
    <SidebarLayout
      darkBackground={darkBackground}
      isRefreshedUi={isRefreshedUi}
      isSidebarRightOpen={isOpen}
      toggleSidebarRight={toggle}
      title={title}
      sidebarOnTopOfHeader={sidebarOnTopOfHeader}
      header={({ setSidebarLeftOpen }: SidebarLayoutChildrenProps) => (
        <LayoutHeader
          workspace={workspace as PublicWorkspaceFragment}
          setSidebarLeftOpen={setSidebarLeftOpen}
          hideHeaderBadge={hideHeaderBadge}
          hideBackButton={hideBackButton}
        >
          <HeaderPortalContainer
            ref={ref => setHeaderPortalContainerRef(ref)}
          />
        </LayoutHeader>
      )}
      sidebarLeft={
        <ShareWorkspaceSidebar workspaceIdentifier={workspace.identifier} />
      }
      sidebarRight={
        <div ref={ref => setSidebarRightPortalContainerRef(ref)}></div>
      }
      footer={<div ref={ref => setFooterPortalContainerRef(ref)}></div>}
      {...rest}
    >
      {(sidebarLayoutProps: SidebarLayoutChildrenProps) => (
        <>
          {render &&
            render({
              ...childrenProps,
              ...sidebarLayoutProps,
            })}
          {Component && (
            <Component {...childrenProps} {...sidebarLayoutProps} />
          )}
        </>
      )}
    </SidebarLayout>
  )
}

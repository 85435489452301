import React, { FC, useState, useEffect, useMemo } from 'react'
import { useRouteMatch } from 'react-router'

import { IS_EMBEDDED } from '@sketch/constants'
import { shortcuts } from '@sketch/utils'
import { ShareWithoutVersion } from 'modules/versioning'
import {
  useForTablet,
  Navbar,
  ModalRestrictor,
  MenuIconButton,
  TooltipShortcut,
} from '@sketch/components'

import HeaderNavigation from '../HeaderNavigation'

import { VersionStatusButton } from './VersionStatusButton'
import DocumentSettingsDropdown from './DocumentSettingsDropdown'
import DocumentNavbar from '../DocumentNavbar'
import { DocumentSidebarLayoutExtraProps } from '../DocumentSidebarLayout'

import { useShareSidebarTab, getActivePanels } from '../ShareSidebarTabContext'
import { NavbarItem, VerticalDivider } from '../NavbarItem'

import { ReactComponent as ClockReWind } from '@sketch/icons/clock-rewind-24'
import { ReactComponent as Information } from '@sketch/icons/information-24'
import { useRefresh24 } from '@sketch/modules-common'
import { VersionFragment } from '@sketch/gql-types'

import DerivedHeaderActions from '../DerivedHeaderActions'

type DocumentHeaderProps = {
  share: ShareWithoutVersion
  isViewingLatestVersion: boolean
  isViewingStarredVersion: boolean
  hasPendingPatches: boolean
  userCanOpenInApp: boolean
  currentVersion: VersionFragment | null
} & Pick<
  DocumentSidebarLayoutExtraProps,
  'setSidebarLeftOpen' | 'toggleSidebarRight' | 'isSidebarRightOpen'
>

/**
 * Used for:
 * - Legacy Page view (SHARE_PAGE_VIEW)
 * - Prototype view (SHARE_PROTOTYPES)
 * - CWV view (symbol, text styles, layer styles or color variables)
 */
export const DocumentHeader: FC<DocumentHeaderProps> = ({
  share,
  toggleSidebarRight,
  isSidebarRightOpen,
  userCanOpenInApp,
  isViewingLatestVersion,
  isViewingStarredVersion,
  hasPendingPatches,
  currentVersion,
}) => {
  // TODO: Remove FF "ui-refresh-24" when its released
  // https://github.com/orgs/sketch-hq/projects/326/views/1?pane=issue&itemId=65739330
  const isRefreshedUi = useRefresh24()

  const isTabletAndBigger = useForTablet()
  const { path } = useRouteMatch()

  const { identifier, userCanInspect, commentsEnabled } = share

  const activePanels = useMemo(
    () =>
      getActivePanels({
        path,
        canInspect: userCanInspect,
        canComment: commentsEnabled,
      }),
    [path, commentsEnabled, userCanInspect]
  )

  const [segments, setSegments] = useState(activePanels)

  useEffect(() => {
    setSegments(activePanels)
  }, [activePanels])

  const { activeSegment, toggleSegment } = useShareSidebarTab(segments, {
    isSidebarRightOpen,
    toggleSidebarRight,
  })

  const showVersionStatusButton = isTabletAndBigger && !IS_EMBEDDED

  return (
    <Navbar $isRefreshedUi={isRefreshedUi} isDocumentView>
      <Navbar.Section>
        <HeaderNavigation share={share} />
      </Navbar.Section>

      <Navbar.FixedWidthSection align="end">
        {isTabletAndBigger && (
          <NavbarItem>
            <DocumentNavbar />
          </NavbarItem>
        )}

        <DerivedHeaderActions
          share={share}
          currentVersion={currentVersion}
          userCanOpenInApp={userCanOpenInApp}
          isViewingLatestVersion={isViewingLatestVersion}
        />

        {showVersionStatusButton && (
          <VersionStatusButton
            shareID={identifier}
            isViewingLatestVersion={isViewingLatestVersion}
            isViewingStarredVersion={isViewingStarredVersion}
            userCanEdit={userCanOpenInApp}
            hasPendingPatches={hasPendingPatches}
          />
        )}

        <NavbarItem>
          <ModalRestrictor>
            <DocumentSettingsDropdown />
          </ModalRestrictor>
        </NavbarItem>

        {isTabletAndBigger && (
          <>
            <VerticalDivider />
            <NavbarItem data-testid="panel-triggers-header">
              <MenuIconButton
                description={
                  <>
                    Document details{' '}
                    <TooltipShortcut>
                      Press {shortcuts.about.toUpperCase()}
                    </TooltipShortcut>
                  </>
                }
                aria-current={activeSegment === 'About'}
                onClick={() => toggleSegment('About')}
              >
                <Information role="img" />
                <span className="sr-only">About</span>
              </MenuIconButton>

              <MenuIconButton
                description={
                  <>
                    Versions{' '}
                    <TooltipShortcut>
                      Press {shortcuts.version.toUpperCase()}
                    </TooltipShortcut>
                  </>
                }
                aria-current={activeSegment === 'Version'}
                onClick={() => toggleSegment('Version')}
              >
                <ClockReWind role="img" />
                <span className="sr-only">Versions</span>
              </MenuIconButton>
            </NavbarItem>
          </>
        )}
      </Navbar.FixedWidthSection>
    </Navbar>
  )
}

export default DocumentHeader

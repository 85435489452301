import React from 'react'

import {
  routes,
  useAnalytics,
  DynamicLoadingPage,
  IndexLayoutOld,
  IndexLayoutErrorOld,
  IndexLayoutOldTitle,
  IndexLayoutOldSubtitle,
  setSketchUrlScheme,
} from '@sketch/modules-common'

import { isBillingHidden, SKETCH_WEBSITE } from '@sketch/env-config'

import {
  LinkButton,
  AnchorButton,
  useForDesktop,
  AsyncButton,
} from '@sketch/components'

import {
  useGetWorkspaceQuery,
  useSendDownloadSketchEmailMutation,
} from '@sketch/gql-types'
import { useGetCurrentUserFlow } from './CreateWorkspaceDoneView.hooks'

import { ReactComponent as MacDownloadIcon } from '@sketch/icons/mac-app-download-48'
import { ReactComponent as BookIcon } from '@sketch/icons/book-open-48'
import { ReactComponent as PaymentIcon } from '@sketch/icons/license-diamond-48'

import {
  Card,
  Cards,
  Description,
  DescriptionPaymentMethod,
  Link,
  Image,
  CtaWrapper,
  Separator,
  CardTitle,
  Footer,
  WorkspaceLogo,
} from './CreateWorkspaceDoneView.styles'
import { useToast } from '@sketch/toasts'

interface CreateWorkspaceDoneProps {
  workspaceId: string
}

const DownloadMacAppCard = () => {
  const isDesktop = useForDesktop()
  const { showToast } = useToast()

  const currentUserFlow = useGetCurrentUserFlow()
  const { trackEvent } = useAnalytics()

  const [requestEmail] = useSendDownloadSketchEmailMutation({
    onError: 'show-toast',
    onCompleted: () => {
      showToast('Email has been sent')
    },
  })

  const downloadButton = isDesktop ? (
    <AnchorButton
      variant="primary"
      size="40"
      href="http://sketch.com/downloads/mac"
      target="_blank"
      onClick={() =>
        trackEvent('WORKSPACE READY - download', { currentUserFlow })
      }
    >
      Download Mac App
    </AnchorButton>
  ) : (
    <AnchorButton
      as={AsyncButton}
      variant="primary"
      size="40"
      onClick={() => {
        return requestEmail()
      }}
    >
      Email Me a Download Link
    </AnchorButton>
  )

  return (
    <Card key="download-mac-app">
      <Image as={MacDownloadIcon} aria-label="Sketch Mac App" />
      <CardTitle>Set up on your Mac</CardTitle>
      <CtaWrapper marginTop={8}>{downloadButton}</CtaWrapper>
      {isDesktop && (
        <>
          <Separator />
          <Description>Already downloaded?</Description>
          <Link
            variant="primary"
            isUnderlined
            external
            href={setSketchUrlScheme(`sketch.cloud`)}
            onClick={() =>
              trackEvent('WORKSPACE READY - launch and sign in', {
                currentUserFlow,
              })
            }
          >
            Launch Mac app & Sign In
          </Link>
        </>
      )}
    </Card>
  )
}

const LearnSketchCard = () => {
  const currentUserFlow = useGetCurrentUserFlow()
  const { trackEvent } = useAnalytics()

  return (
    <Card key="learn-sketch">
      <Image as={BookIcon} aria-label="Learn Sketch" />
      <CardTitle>Learn Sketch</CardTitle>
      <Description marginTop={8}>
        Whether an absolute beginner or Sketch veteran, we’ve got in-depth
        guides
      </Description>
      <CtaWrapper marginTop={24}>
        <AnchorButton
          variant="secondary"
          size="40"
          href={`${SKETCH_WEBSITE}/docs/`}
          onClick={() =>
            trackEvent('WORKSPACE READY - start learning sketch', {
              currentUserFlow,
            })
          }
        >
          How to use the Mac app
        </AnchorButton>
      </CtaWrapper>
    </Card>
  )
}

const AddPaymentMethodCard = ({ workspaceId }: { workspaceId: string }) => {
  const currentUserFlow = useGetCurrentUserFlow()
  const { trackEvent } = useAnalytics()

  return (
    <Card key="add-payment-method">
      <Image as={PaymentIcon} aria-label="Add Payment Method" />
      <CardTitle>Add Payment Method</CardTitle>
      <DescriptionPaymentMethod marginTop={8}>
        30 days left in your trial, but you can add payment ahead of time
      </DescriptionPaymentMethod>
      <CtaWrapper marginTop={24}>
        <LinkButton
          variant="secondary"
          size="40"
          to={routes.WORKSPACE_SETTINGS_BILLING.create({ workspaceId })}
          onClick={() =>
            trackEvent('WORKSPACE READY - set up payment', { currentUserFlow })
          }
        >
          Change Billing Settings
        </LinkButton>
      </CtaWrapper>
    </Card>
  )
}

const CreateWorkspaceDoneView = (props: CreateWorkspaceDoneProps) => {
  const { workspaceId } = props
  const { trackEvent } = useAnalytics()

  const { loading, data } = useGetWorkspaceQuery({
    variables: { identifier: workspaceId },
  })
  const currentUserFlow = useGetCurrentUserFlow()

  if (loading) {
    return <DynamicLoadingPage />
  }

  const workspace = data?.workspace

  if (!workspace) {
    return <IndexLayoutErrorOld />
  }

  const isFinance = workspace.userRole === 'FINANCE'

  /**
   * We can assume that the invited member will not be
   * a owner at the time of seeing this screen (with the "Welcome to WorkspaceName!" title)
   *
   * Because this view is the finalizing state for both CreateWorkspace and AcceptWorkspaceInviteView
   */
  const invitedMember = !workspace.userIsOwner

  const headerLink = invitedMember ? 'none' : 'workspace-settings'

  const subtitleCopyEditor = invitedMember
    ? 'Download the Mac App and sign in to get started.'
    : 'Get creative with the Mac app and save to the Workspace'

  return (
    <IndexLayoutOld headerLink={headerLink} fullWidth>
      <IndexLayoutOldTitle>
        {invitedMember ? `Welcome to ${workspace.name}!` : 'You’re all set up'}
      </IndexLayoutOldTitle>
      <IndexLayoutOldSubtitle>
        {workspace?.userCanEdit
          ? subtitleCopyEditor
          : 'Learn more about Sketch and explore your Workspace.'}
      </IndexLayoutOldSubtitle>
      <Cards>
        {workspace?.userCanEdit ? <DownloadMacAppCard /> : null}
        <LearnSketchCard />
        {!isBillingHidden && workspace?.userRole === 'ADMIN' ? (
          <AddPaymentMethodCard workspaceId={workspaceId} />
        ) : null}
      </Cards>
      <Footer>
        <LinkButton
          onClick={() =>
            trackEvent('WORKSPACE READY - go to workspace', { currentUserFlow })
          }
          to={
            isFinance
              ? routes.WORKSPACE_SETTINGS.create({ workspaceId })
              : routes.WORKSPACE_DISCOVER.create({ workspaceId })
          }
          variant="primary"
        >
          <>
            {workspace?.avatar?.large && (
              <WorkspaceLogo
                src={workspace?.avatar?.large}
                workspaceName={workspace.name}
                size="24px"
              />
            )}
            Continue to the Workspace
          </>
        </LinkButton>
      </Footer>
    </IndexLayoutOld>
  )
}

export default CreateWorkspaceDoneView

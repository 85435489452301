import styled, { css } from 'styled-components'

import { Pill, RadioButton, Box, Stepper } from '@sketch/components'
import { Text } from 'modules/workspace/views/WorkspaceSubscribeView/WorkspaceSubscribeView.styles'
import { StepperContainer } from 'modules/workspace/components/AddEditors/AddEditors.styles'

import { PlansWrapper } from '../../components/PlanPicker/PlanPicker.styles'

import { breakpoint } from '@sketch/global-styles'

import { ReactComponent as QuestionMarkIconUnstyled } from '@sketch/icons/question-mark-16'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: 448px 450px;
  grid-column-gap: 141px; /* stylelint-disable-line scales/space */
  margin: 64px auto 0;

  ${breakpoint('base', 'lg')`
    grid-template-columns: auto;
  `}
`

interface PlanPickerWrapperProps {
  numPlans: number
}

export const PlanPickerWrapper = styled.div<PlanPickerWrapperProps>`
  margin-top: 32px;

  ${({ numPlans }) =>
    numPlans > 2 &&
    css`
      & > ${PlansWrapper} {
        ${breakpoint('base', 'xs')`
          justify-content: space-between;
          align-items: center;
          flex-direction: column;

          & > * {
            width: 80%;
          }
        `}
      }
    `}
`

export const BillingCycleRadioButtons = styled.div`
  display: flex;
  margin-top: 24px;
`

export const Section = styled.section`
  margin-bottom: 36px;

  ${StepperContainer} {
    margin-top: -18px; /* stylelint-disable-line scales/space */
  }

  ${Stepper} {
    margin-bottom: 20px;
  }
`

export const BillSummaryWrapper = styled.section`
  margin-bottom: 24px;

  ${breakpoint('base', 'sm')`
    margin-top: 32px;
  `}
`

export const TextWithTooltip = styled(Text)`
  display: flex;
  align-items: center;

  span {
    margin-left: 4px;
    height: 16px;
  }

  line-height: 1.4;

  ${breakpoint('base', 'xs')`
    display: inline-block;
  `}
`

export const StyledPill = styled(Pill)`
  margin-left: 4px;
  margin-right: 24px;

  .active & {
    background-color: ${({ theme }) => theme.colors.sketch.F};
    color: ${({ theme }) => theme.colors.sketch.A};
  }
`

// This margin is added on purpose to deal with the fact the component is wrapped in a tooltip
// and disabled in some states and the tooltip is not dismissed when we move the mouse out of the component
// passing through the disabled radio button element
export const RadioButtonStyled = styled(RadioButton)`
  margin: 4px;
  pointer-events: all;
  display: flex;
  align-items: center;
  & > ${Box} {
    display: flex;
  }
`

export const RadioButtonContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > ${Box} {
    display: flex;
  }
`

export const TooltipText = styled.p`
  margin: 0 0 8px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.ui.tooltips.foreground};

  :last-child {
    margin: 0;
  }

  b {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.ui.tooltips.title};
  }
`
export const QuestionMarkIcon = styled(QuestionMarkIconUnstyled)`
  width: 16px;
  height: 16px;
  cursor: pointer;
`

import React, { useCallback, useState } from 'react'
import { Redirect } from 'react-router'
import { GraphQLError } from 'graphql'
import { ApolloError } from 'apollo-client'

import { useToast } from '@sketch/toasts'
import { MemberCard } from '../../components/MemberCard'

import {
  RouteProps,
  routes,
  IndexLayoutOld,
  IndexLayoutErrorOld,
  IndexLayoutLoadingOld,
  useQueryParams,
  useSignOut,
  isSsoAuthorization,
  useUserAuthorizations,
} from '@sketch/modules-common'

import { LocationState as CreateWorkspaceDoneLocationState } from '../CreateWorkspaceDoneView'

import {
  RoleText,
  DifferentAccountText,
  StyledButton,
  StyledLogo,
  StyledTitle,
  StyledSubtitle,
  Wrapper,
  WrapperMemberCard,
  InfoText,
} from './AcceptWorkspaceInviteView.styles'

import {
  useAcceptWorkspaceInviteMutation,
  useGetPendingWorkspaceMembershipQuery,
} from '@sketch/gql-types'

import { getMemberEmail } from '../../utils'

/**
 * AcceptWorkspaceInviteView
 *
 * Renders when a user clicks in workspace invitation email.
 *
 * Query Param: token
 *
 * There are 3 different flows:
 *    1. User opens invitation logged in with the same email as the
 *       invitation email (invited email or user email is supplied);
 *
 *    2. User opens invitation logged in with a different email and
 *       the invited email is not registered in Sketch (invited email is supplied);
 *
 *    3. User opens invitation logged in with a different email and
 *       the invited email is already registered in Sketch (user email is supplied);
 */
const AcceptWorkspaceInviteView: React.FC<
  RouteProps<'WORKSPACE_INVITE'>
> = props => {
  const { location, history } = props
  const { authorizations } = useUserAuthorizations()
  const isSignedInWithSso = authorizations.some(isSsoAuthorization)

  const { token } = useQueryParams<'WORKSPACE_INVITE'>()
  const { showToast } = useToast()

  const [signOutLocation] = useState({
    pathname: routes.SIGN_IN.create({}),
    state: {
      from: location,
      hideSSO: true,
    },
  })

  const signOut = useSignOut({
    location: signOutLocation,
    reason: 'Switching account to accept workspace invitation',
  })

  const handleOnError = useCallback(
    (error: ApolloError) => {
      const negativeErrors: GraphQLError[] = []
      const positiveErrors: GraphQLError[] = []

      error.graphQLErrors.forEach(error => {
        if (error.extensions?.code === 'INVALID_INPUT') {
          positiveErrors.push(error)
        } else {
          negativeErrors.push(error)
        }
      })

      /**
       * If the error code is "INVALID_INPUT" it was established
       * that this would translate to a already accepted invite by the user
       * therefore even tho it's an error it should show a positive message
       */
      if (positiveErrors.length > 0) {
        showToast(
          positiveErrors.map(error => error.message).join('; '),
          'positive'
        )
      } else {
        showToast(
          negativeErrors.map(error => error.message).join('; '),
          'negative'
        )
      }
    },
    [showToast]
  )

  const { loading, error, data } = useGetPendingWorkspaceMembershipQuery({
    variables: { membershipToken: token || '' },
    skip: !token,
    onError: handleOnError,
  })

  const [
    acceptInvite,
    { loading: loadingAcceptInvitation },
  ] = useAcceptWorkspaceInviteMutation({
    variables: { membershipToken: token! },
    onError: 'show-toast',
    onCompleted: data => {
      const {
        identifier: workspaceId,
      } = data.acceptWorkspaceInvite.membership.workspace
      const locationState: CreateWorkspaceDoneLocationState = {
        currentUserFlow: 'accept-workspace-invite',
      }
      history.replace(
        routes.WORKSPACE_CREATE_DONE.create({ workspaceId }),
        locationState
      )
    },
  })

  if (loading) {
    return <IndexLayoutLoadingOld />
  }

  /* If the invite is already accepted BE will return an error */
  if (error) {
    /**
     * If the error is "INVALID_INPUT" and "teamId" is present
     * we can assume this invite was already accepted and the
     * user should be redirect to the workspace
     */
    const possibleAcceptedError = error.graphQLErrors.find(
      error => error.extensions?.code === 'INVALID_INPUT'
    )

    const workspaceId = possibleAcceptedError?.extensions?.context.find(
      (c: { key: string; value: string }) => c.key === 'teamId'
    )?.value

    if (workspaceId) {
      return <Redirect to={routes.WORKSPACE_SHARES.create({ workspaceId })} />
    } else {
      return <Redirect to={routes.ENTRY.create({})} />
    }
  }

  if (!token || !data?.pendingWorkspaceMembership) {
    return <IndexLayoutErrorOld />
  }

  const { me, pendingWorkspaceMembership } = data
  const { workspace, invite, user, isEditor, role } = pendingWorkspaceMembership
  const workspaceName = workspace.name
  const isPartner = role === 'PARTNER'

  let content: React.ReactNode = null

  const onAcceptInvitation = () => {
    // We cannot accept an invitation being signed in with SSO
    if (isSignedInWithSso) {
      return signOut()
    }

    return acceptInvite()
  }

  if (user && user.email !== me.email) {
    /**
     * This case exists when the invited email already has a account created
     * but the current session is not the same as the invited one.
     *
     * In this case the user has to switch the invited account.
     */
    content = (
      <>
        <DifferentAccountText>
          You’re currently signed in as <b>{me.email}</b>. Switch accounts to
          accept the invitation.
        </DifferentAccountText>
        <StyledButton fill onClick={() => signOut()} variant="primary">
          {isPartner ? 'Switch to another account' : 'Switch account to accept'}
        </StyledButton>
      </>
    )
  } else if (invite && invite?.email !== me.email) {
    /**
     * This case is when the invited email doesn't have a email account associated with it
     * The user can either go back and create a new account or accept it with the current account
     * he is in, moving the invite to the current user.
     */
    content = (
      <>
        <DifferentAccountText>
          You’re currently signed in as <b>{me.email}</b>.
        </DifferentAccountText>
        <StyledButton
          variant="primary"
          loading={loadingAcceptInvitation}
          onClick={onAcceptInvitation}
          fill
        >
          Accept invitation as {me.email}
        </StyledButton>
        <StyledButton fill onClick={() => signOut()}>
          Switch to another account
        </StyledButton>
      </>
    )
  } else if (isPartner) {
    return (
      <Redirect
        to={routes.WORKSPACE_SUBSCRIBE_PARTNER.create({
          query: {
            token,
          },
        })}
      />
    )
  } else {
    /**
     * This case the invited email is the same as the user account
     */
    content = (
      <>
        <StyledButton
          variant="primary"
          loading={loadingAcceptInvitation}
          onClick={onAcceptInvitation}
          fill
        >
          Join {workspace.name}
        </StyledButton>
      </>
    )
  }

  const roleText = isEditor ? (
    <>
      an <b>Editor</b>
    </>
  ) : (
    <>
      a <b>Viewer</b>
    </>
  )

  return (
    <IndexLayoutOld headerLink="workspace-settings">
      <StyledSubtitle>You’ve been invited to join</StyledSubtitle>
      <StyledTitle>{workspaceName}</StyledTitle>
      <StyledLogo
        workspaceName={workspaceName}
        size="64px"
        src={workspace?.avatar?.small}
      />
      <Wrapper>
        {(invite || user)?.email !== me.email && (
          <InfoText>This invitation was originally sent to:</InfoText>
        )}
        <WrapperMemberCard>
          <MemberCard
            name={user?.name}
            email={getMemberEmail(pendingWorkspaceMembership)!}
            avatarSrc={user?.avatar?.small}
          />
          <RoleText>As {roleText}</RoleText>
        </WrapperMemberCard>
        {content}
      </Wrapper>
    </IndexLayoutOld>
  )
}

export default AcceptWorkspaceInviteView

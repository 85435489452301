import React from 'react'
import { Route, Redirect, Switch, useHistory } from 'react-router-dom'
import AcceptMembershipPanel from './AcceptMembershipPanel'
import SignUpAndAcceptMembershipPanel, {
  SignUpAndAcceptMembershipPanelProps,
} from './SignUpAndAcceptMembershipPanel'
import SignInAndAcceptMembershipPanel from './SignInAndAcceptMembershipPanel'
import SwitchAccountOrAcceptMembershipPanel from './SwitchAccountOrAcceptMembershipPanel'
import ForgotPasswordPanel from './ForgotPasswordPanel'
import { Location, History } from 'history'
import {
  useGetShareMembershipQuery,
  useShareRequestAccessMutation,
} from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'
import {
  routes as routesExternal,
  NotFoundView,
  IsSignedIn,
  IsNotSignedIn,
  InvalidInvitationView,
  IndexLayoutExtraProps,
  useProfileInfo,
  useSignOut,
  getAllAuthorizations,
} from '@sketch/modules-common'
import * as Sentry from '@sentry/browser'
import jwtDecode from 'jwt-decode'
import { Flex, LoadingState } from '@sketch/components'
import queryString from 'query-string'
import useAcceptShareMembershipInvitation from '../operations/useAcceptShareMembershipInvitation'
import { pick } from 'lodash'

const routes = pick(routesExternal, [
  'SHARE_ACCEPT_INVITE',
  'SHARE_ACCEPT_INVITE_SIGN_IN',
  'SHARE_ACCEPT_INVITE_SIGN_UP',
  'SHARE_ACCEPT_INVITE_FORGOT_PASSWORD',
])
export const acceptShareInviteByInviteTokenRoutes = routes

const useInviteToken = (queryToken: string | string[] | null) => {
  let inviteToken: string | null = null
  let shareShortId: string | null = null
  let documentName: string | null = null

  if (queryToken) {
    inviteToken = Array.isArray(queryToken) ? queryToken[0] : queryToken
    try {
      // eslint-disable-next-line no-extra-semi
      ;({
        document_name: documentName,
        share_short_id: shareShortId,
      } = jwtDecode(inviteToken))
    } catch {
      inviteToken = shareShortId = documentName = null
    }
  }

  return { inviteToken, documentName, shareShortId }
}

interface AcceptShareInviteByInviteTokenViewProps
  extends IndexLayoutExtraProps {
  history: History
  location: Location
}

interface AcceptInviteProps extends SignUpAndAcceptMembershipPanelProps {
  location: Location
  invitedEmail: string
}
/**
 * We needed to move the routes logic into a separate component because
 * the component that was encapsulating all the logic wasn't updating
 * the user profile. Accesing it from this component works perfectly
 */
const AcceptInviteRoute = ({
  location,
  invitedEmail,
  ...acceptShareMembershipPanelProps
}: AcceptInviteProps) => {
  const history = useHistory()
  const { showToast } = useToast()
  const { userEmail, userAvatar, hasPersonalIdentity } = useProfileInfo()
  const allAuths = getAllAuthorizations()

  const signOut = useSignOut({
    location: {
      pathname: routes.SHARE_ACCEPT_INVITE_SIGN_IN.create({}),
      search: location.search,
      state: { from: location },
    },
    reason: 'Switching account to accept share invitation',
  })

  const [requestShareAccess] = useShareRequestAccessMutation({
    redirectErrors: true,
    variables: { shortId: acceptShareMembershipPanelProps.shareShortId },
    onError: ({ message }) => {
      showToast(message, 'negative')
      /* Redirect home of the user already requested access before */
      if (message === "You've already requested access") {
        history.push(routesExternal.ENTRY.create({}))
      }
    },
    onCompleted: () => {
      showToast('Request has been sent')
      /* Redirect home instead of showing the ShareAuthorizer NotFound */
      history.push(routesExternal.ENTRY.create({}))
    },
  })

  const isCurrentUserInvited = invitedEmail === userEmail

  // Just to get rid of TS errors
  if (!userEmail) return null

  const hasOnlySSOAuths =
    allAuths.length > 0 && allAuths.every(auth => auth.type === 'sso')

  // if the user has a personal identity (not SSO only) and has SSO auth,
  // we should show the switch account panel, not the accept membership panel
  if (isCurrentUserInvited && hasPersonalIdentity && !hasOnlySSOAuths) {
    return (
      <AcceptMembershipPanel
        resourceType="document"
        userInfo={{
          userEmail,
          userAvatar,
        }}
        onRequestAccessWithDifferentAccount={signOut}
        {...acceptShareMembershipPanelProps}
      />
    )
  }

  return (
    <SwitchAccountOrAcceptMembershipPanel
      resourceType="document"
      invitedEmail={invitedEmail}
      userInfo={{
        userEmail,
        userAvatar,
      }}
      onRequestAccess={requestShareAccess}
      onSwitchAccount={signOut}
      {...acceptShareMembershipPanelProps}
    />
  )
}

export const AcceptShareInviteByInviteTokenView = (
  props: AcceptShareInviteByInviteTokenViewProps
) => {
  const { history, location } = props
  const { showToast } = useToast()

  const query = queryString.parse(location.search)
  const queryToken = query.token

  const { inviteToken, documentName, shareShortId } = useInviteToken(
    queryToken as string | string[] | null
  )

  if (!inviteToken) {
    Sentry.captureMessage('Failed to parse invite token')
  }

  const { data, error, loading } = useGetShareMembershipQuery({
    variables: { token: inviteToken },
    skip: !inviteToken,
  })

  const [
    acceptMembership,
    { loading: acceptMembershipLoading, error: acceptMembershipError },
  ] = useAcceptShareMembershipInvitation({
    variables: { token: inviteToken },
    onCompleted: () => {
      showToast('Invite successfully accepted')
      history.push(
        routesExternal.SHARE_VIEW.create({ shareID: shareShortId ?? '' })
      )
    },
    onError: 'do-nothing', // we use acceptShareMembershipError to show the error message
  })

  if (loading) {
    return <LoadingState />
  }

  if (!inviteToken || !shareShortId || !data || error) {
    return <NotFoundView isInLayout />
  }

  const { shareMembership } = data

  if (!shareMembership) {
    return <InvalidInvitationView />
  }

  const invitedEmail = shareMembership?.email ?? ''

  if (shareMembership?.inviteStatus === 'ACCEPTED' && shareShortId !== null)
    return (
      <Redirect
        to={routesExternal.SHARE_VIEW.create({
          shareID: shareShortId,
        })}
      />
    )

  const acceptShareMembershipPanelProps = {
    shareShortId,
    acceptMembership,
    acceptMembershipError,
    acceptMembershipLoading,
    inviter: shareMembership?.inviter,
    inviteToken,
    resourceName: documentName,
    invitedEmail,
  } as SignUpAndAcceptMembershipPanelProps

  return (
    <>
      <Flex
        maxWidth="512px"
        width="100%"
        alignSelf="center"
        flex="1"
        alignItems="center"
        justifyContent="center"
      >
        {/* TODO: move and flatten out AcceptShareInviteByInviteTokenView routing from AcceptShareInviteByInviteTokenView to AcceptInviteRoutes
         * see: https://github.com/sketch-hq/Cloud/issues/17153
         */}
        <Switch>
          <Route path={routes.SHARE_ACCEPT_INVITE_FORGOT_PASSWORD.template()}>
            <ForgotPasswordPanel invitedEmail={invitedEmail} />
          </Route>
          <Route path={routes.SHARE_ACCEPT_INVITE_SIGN_UP.template()}>
            <SignUpAndAcceptMembershipPanel
              {...acceptShareMembershipPanelProps}
            />
          </Route>
          <Route path={routesExternal.SHARE_ACCEPT_INVITE.template()}>
            <IsSignedIn>
              <AcceptInviteRoute
                location={location}
                {...acceptShareMembershipPanelProps}
              />
            </IsSignedIn>
            <IsNotSignedIn>
              <Redirect
                to={{
                  pathname: routes.SHARE_ACCEPT_INVITE_SIGN_IN.template(),
                  search: location.search,
                }}
              />
            </IsNotSignedIn>
          </Route>
          <Route path={routes.SHARE_ACCEPT_INVITE_SIGN_IN.template()}>
            <SignInAndAcceptMembershipPanel
              {...acceptShareMembershipPanelProps}
            />
          </Route>
          <Redirect
            to={{
              pathname: routesExternal.SHARE_ACCEPT_INVITE.template(),
              search: location.search,
            }}
          />
        </Switch>
      </Flex>
    </>
  )
}

import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import queryString from 'query-string';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import { useQueryParams, useAnalytics, getIsForInAppPresentation, routes, IndexLayoutContent, IndexLayoutHeaderLink, RedirectBadge, IndexLayoutOldTitle, SignInForm } from '@sketch/modules-common';
import { SketchLoginDeprecationModal } from '@sketch/user';
import { useModalContext, DocumentHead, OrderedBreadcrumbs, Link } from '@sketch/components';
import { BreadcrumbsWrapper, FormContainer, StyledLinkButton, Line, BottomSection, Paragraph } from './SignInView.styles.js';

const SIGN_IN_CRUMBS = [{ content: 'Sign In' }, { content: 'Create Workspace' }];
const SignInView = ({ history, location, HeaderPortal, }) => {
    var _a, _b;
    const { sketch } = useQueryParams();
    const { trackSimpleAnalytics, removeSimpleAnalytics } = useAnalytics();
    const isForInAppPresentation = getIsForInAppPresentation();
    const { state } = location;
    const linkState = { from: state === null || state === void 0 ? void 0 : state.from };
    const showSSOSignInButton = !(state === null || state === void 0 ? void 0 : state.hideSSO);
    useEffect(() => {
        trackSimpleAnalytics();
        return () => removeSimpleAnalytics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { email } = queryString.parse(((_a = state === null || state === void 0 ? void 0 : state.from) === null || _a === void 0 ? void 0 : _a.search) || '');
    const { showModal } = useModalContext();
    const target = isForInAppPresentation ? '_self' : '_blank';
    const willContinueToWorkspaceCreation = ((_b = state === null || state === void 0 ? void 0 : state.from) === null || _b === void 0 ? void 0 : _b.pathname) === routes.WORKSPACE_CREATE.create({});
    return (jsxs(IndexLayoutContent, Object.assign({ center: "horizontal", marginTop: true, paddingHorizontal: true }, { children: [jsx(HeaderPortal, { children: jsx(IndexLayoutHeaderLink, { withDownload: true }) }), jsxs(Helmet, { children: [jsx("link", { rel: "canonical", href: "https://www.sketch.com/signin/" }), jsx("meta", { property: "og:url", content: "https://www.sketch.com/signin/" })] }), jsx(DocumentHead, { title: "Sign in - It's great to see you again" }), jsxs(Fragment, { children: [(state === null || state === void 0 ? void 0 : state.from) && jsx(RedirectBadge, Object.assign({ from: state.from }, state)), willContinueToWorkspaceCreation && (jsx(BreadcrumbsWrapper, { children: jsx(OrderedBreadcrumbs, { crumbs: SIGN_IN_CRUMBS, currentCrumb: 0 }) })), jsx(IndexLayoutOldTitle, { children: "Sign in to Sketch" }), jsxs(FormContainer, { children: [jsx(SignInForm, { email: email, onCompleted: data => {
                                    var _a;
                                    // MFA auth
                                    if (data.signIn.__typename === 'MFACredentials') {
                                        history.push(routes.VERIFICATION_CODE.create({}), {
                                            mfaToken: data.signIn.mfaToken,
                                            from: state === null || state === void 0 ? void 0 : state.from,
                                        });
                                        return;
                                    }
                                    // U+P auth
                                    if (sketch === 'true') {
                                        showModal(SketchLoginDeprecationModal);
                                    }
                                    // Partner invitation URL contains the "partner" parameter. This
                                    // allow us to go directly to the subscribe page without passing
                                    // on AcceptWorkspaceInviteView and be redirected from there.
                                    const destinationRouteParams = new URLSearchParams((_a = state === null || state === void 0 ? void 0 : state.from) === null || _a === void 0 ? void 0 : _a.search);
                                    const isPartner = destinationRouteParams.get('partner');
                                    const token = destinationRouteParams.get('token');
                                    if (isPartner && token) {
                                        history.push(routes.WORKSPACE_SUBSCRIBE_PARTNER.create({
                                            query: {
                                                token,
                                            },
                                        }));
                                        return;
                                    }
                                    if (willContinueToWorkspaceCreation) {
                                        history.push(routes.WORKSPACE_CREATE.create({}), {
                                            fromSignIn: true,
                                        });
                                    }
                                    else {
                                        history.push((state === null || state === void 0 ? void 0 : state.from) || routes.ENTRY.create({}));
                                    }
                                } }), showSSOSignInButton && (jsx(StyledLinkButton, Object.assign({ size: "40", variant: "secondary", to: {
                                    pathname: routes.SSO_SIGN_IN.create({}),
                                    state: linkState,
                                } }, { children: "Sign in with SSO" }))), jsx(Line, {}), jsxs(BottomSection, { children: [!isForInAppPresentation && (jsxs(Paragraph, { children: ["New to Sketch?", ' ', jsx(Link, Object.assign({ variant: "primary", isUnderlined: true, to: {
                                                    pathname: routes.SIGN_UP.create({}),
                                                    state,
                                                } }, { children: "Create an account" }))] })), jsxs(Paragraph, { children: ["Using Sketch with a License?", ' ', jsx(Link, Object.assign({ variant: "primary", isUnderlined: true, target: target, href: `${SKETCH_WEBSITE}/support/license-manager`, external: true }, { children: "Learn more" }))] })] })] })] })] })));
};

export { SignInView as default };

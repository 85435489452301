import { jsx, jsxs } from 'react/jsx-runtime';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToast } from '@sketch/toasts';
import { useAnalytics, useFlag, useUserProfile, routes, useSignOut, IndexLayoutContent, IndexLayoutOldTitle, IndexLayoutOldSubtitle } from '@sketch/modules-common';
import { Wrapper, TextInbox, StyledLinkButton, DoThisLaterButton } from './CheckYourInboxView.styles.js';
import { useResendVerificationEmailMutation } from '@sketch/gql-types';

const CheckYourInboxView = (props) => {
    var _a;
    const history = useHistory();
    const { showToast } = useToast();
    const { trackEvent } = useAnalytics();
    // TODO: Remove usage of mandatory-email-verification when release to production
    // https://github.com/sketch-hq/Cloud/issues/11277
    const isMandatoryEmailVerification = useFlag('mandatory-email-verification');
    const { data } = useUserProfile();
    // Redirect already verified users to the entry route
    useEffect(() => {
        if (!isMandatoryEmailVerification) {
            if (data === null || data === void 0 ? void 0 : data.me.isVerified)
                history.push(routes.ENTRY.create({}));
        }
    }, [data, history, isMandatoryEmailVerification]);
    // Operations
    const [resendVerificationEmail] = useResendVerificationEmailMutation({
        redirectErrors: true,
        onCompleted: () => showToast(`An email has been sent to ${data === null || data === void 0 ? void 0 : data.me.email}`),
        onError: 'show-toast',
    });
    const signOut = useSignOut({
        location: routes.SIGN_UP.create({
            query: {
                email: encodeURIComponent((_a = data === null || data === void 0 ? void 0 : data.me.email) !== null && _a !== void 0 ? _a : ''),
            },
        }),
        reason: 'Mistyped email button',
    });
    return (jsx(IndexLayoutContent, Object.assign({ center: true, paddingHorizontal: true }, { children: jsxs(Wrapper, { children: [jsx(IndexLayoutOldTitle, { children: "Check Your Inbox" }), jsxs(IndexLayoutOldSubtitle, { children: ["A verification email is on its way to", ' ', jsx("strong", { children: data === null || data === void 0 ? void 0 : data.me.email }), ".", jsx("br", {}), "Confirm your email to start using Sketch."] }), jsx(TextInbox, { children: jsxs("span", { children: ["Can't find the email?", ' ', jsx(StyledLinkButton, Object.assign({ onClick: () => {
                                    trackEvent('ONBOARDING - resend verify email', {
                                        source: 'check your inbox page',
                                    });
                                    resendVerificationEmail();
                                } }, { children: "Resend" })), ' ', "the verification email"] }) }), jsx(TextInbox, { children: jsxs("span", { children: ["Mistyped your email?\u00A0", jsx(StyledLinkButton, Object.assign({ variant: "secondary", onClick: signOut }, { children: "Sign out" })), "\u00A0and try again"] }) }), !isMandatoryEmailVerification && (jsx(DoThisLaterButton, Object.assign({ to: routes.WORKSPACE_AGNOSTIC_DISCOVER.create({}), variant: "primary-untinted", onClick: () => {
                        trackEvent('ONBOARDING - skip email verification');
                    } }, { children: "Do This Later" })))] }) })));
};

export { CheckYourInboxView };

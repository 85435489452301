import styled from 'styled-components'
import { breakpoint } from '@sketch/global-styles'

export const PlansWrapper = styled.section`
  display: flex;

  ${breakpoint('base', 'xs')`
      justify-content: space-around;
  `}
`

import { __rest } from 'tslib';
import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Text, useModalContext, AnchorButton, AsyncButton, LinkButton, useBreakpoint } from '@sketch/components';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import { isTouchDevice, isMac, useDetectTouchSwipe } from '@sketch/utils';
import { useSendDownloadSketchEmailMutation } from '@sketch/gql-types';
import { useToast } from '@sketch/toasts';
import { useAnalytics, LaunchSketchAlert, routes, IndexLayoutContent } from '@sketch/modules-common';
import MacApp from '@sketch/icons/mac-app-logo-128.png';
import MacApp2x from '@sketch/icons/mac-app-logo-128@2x.png';
import MacApp4x from '@sketch/icons/mac-app-logo-128@4x.png';
import WebApp from '@sketch/icons/web-app-logo-128.png';
import WebApp2x from '@sketch/icons/web-app-logo-128@2x.png';
import WebApp4x from '@sketch/icons/web-app-logo-128@4x.png';
import WebBackground from '@sketch/icons/web-app-background.svg';
import MacBackground from '@sketch/icons/mac-app-background.svg';
import { ItemWrapper, ItemBackground, ItemBackgroundImage, ItemIllustration, ItemTitle, ItemDescription, ItemAnchorButton, ItemAnchor, SegmentControl, Section, TitleWrapper, SectionTitle, SectionDescription } from './ChooseYourPathView.styles.js';

const SEGMENTS = ['Explore', 'Design'];
const MAX_BACKGROUND_OPACITY = 0.15;
const ILLUSTRATION_SOURCE_BY_NAME = {
    'mac-app': {
        alt: 'Mac App illustration',
        src: MacApp,
        srcSet: `${MacApp2x} 2x, ${MacApp4x} 3x`,
        backgroundImage: MacBackground,
    },
    'web-app': {
        alt: 'Web App illustration',
        src: WebApp,
        srcSet: `${WebApp2x} 2x, ${WebApp4x} 3x`,
        backgroundImage: WebBackground,
    },
};
const Item = (props) => {
    const { title, description, action, icon } = props;
    const _a = ILLUSTRATION_SOURCE_BY_NAME[icon], { backgroundImage } = _a, illustrationProps = __rest(_a, ["backgroundImage"]);
    const [mouseX, setMouseX] = useState(0);
    useEffect(() => {
        if (isTouchDevice()) {
            return;
        }
        const handleMouseMove = (event) => {
            setMouseX(event.clientX);
        };
        document.addEventListener('mousemove', handleMouseMove);
        return () => document.removeEventListener('mousemove', handleMouseMove);
    }, []);
    const wrapperRef = useRef(null);
    const opacity = useMemo(() => {
        var _a;
        const wrapperBounds = (_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.getClientRects()[0];
        const wrapperLeft = (wrapperBounds === null || wrapperBounds === void 0 ? void 0 : wrapperBounds.left) || 0;
        const wrapperWidth = (wrapperBounds === null || wrapperBounds === void 0 ? void 0 : wrapperBounds.width) || 0;
        const halfWrapperWidth = wrapperWidth / 2;
        const mouseXPositionRelatedToItemCenter = Math.abs(mouseX - wrapperLeft - halfWrapperWidth) / halfWrapperWidth;
        const opacity = MAX_BACKGROUND_OPACITY * (1 - mouseXPositionRelatedToItemCenter);
        return Math.max(0, Math.min(opacity, 1));
    }, [mouseX]);
    return (jsxs(ItemWrapper, Object.assign({ ref: wrapperRef }, { children: [jsx(ItemBackground, Object.assign({ style: { opacity: opacity } }, { children: jsx(ItemBackgroundImage, { style: {
                        /* "as any" was used because react doesn't seem to accept a string here ¯\_(ツ)_/¯ */
                        backgroundImage: `url(${backgroundImage})`,
                    } }) })), jsx(ItemIllustration, Object.assign({}, illustrationProps)), jsx(Text, Object.assign({ textStyle: "header.primary.H", as: ItemTitle }, { children: title })), jsx(Text, Object.assign({ textStyle: "copy.quaternary.standard.F", as: ItemDescription }, { children: description })), action] })));
};
const DesignOnYourMac = ({ isMacDesktop }) => {
    const { showToast } = useToast();
    const { trackEvent } = useAnalytics();
    const [requestEmail] = useSendDownloadSketchEmailMutation({
        onError: 'show-toast',
        onCompleted: () => {
            showToast('Email has been sent');
        },
    });
    const { showModal } = useModalContext();
    const action = isMacDesktop ? (jsxs(Fragment, { children: [jsx(AnchorButton, Object.assign({ variant: "primary", size: "48", href: `${SKETCH_WEBSITE}/downloads/mac`, target: "_blank", onClick: () => {
                    trackEvent('CHOOSE YOUR PATH - Download the Mac App button clicked');
                } }, { children: "Download for macOS" })), jsx(Text, Object.assign({ textStyle: "link.sketch.E", as: ItemAnchorButton, onClick: () => {
                    showModal(LaunchSketchAlert, { action: 'openInSketch', shareId: '' });
                    trackEvent('CHOOSE YOUR PATH - Open Mac App button clicked');
                } }, { children: "Already Installed? Open Sketch \u2197" }))] })) : (jsxs(Fragment, { children: [jsx(AsyncButton, Object.assign({ variant: "secondary", size: "48", onClick: () => {
                    trackEvent('CHOOSE YOUR PATH - Send Mac App download email button clicked');
                    return requestEmail();
                } }, { children: "Email Me a Download Link" })), jsx(Text, Object.assign({ textStyle: "caption.secondary.B", as: ItemAnchor }, { children: "Not on your Mac? Grab a download link for later" }))] }));
    return (jsx(Item, { title: "Design on Your Mac", description: "Design, prototype, and illustrate in a truly native macOS app. Work in private \u2014 or collaborate in real time.", icon: "mac-app", action: action }));
};
const ExploreFromYourBrowser = () => {
    const { trackEvent } = useAnalytics();
    return (jsx(Item, { title: "Explore from Your Browser", description: "Inspect, organize and discuss designs with your team, or invite stakeholders as Guests \u2014 on any device or browser.", icon: "web-app", action: jsx(LinkButton, Object.assign({ variant: "primary", size: "48", to: routes.ENTRY.create({}), onClick: () => {
                trackEvent('CHOOSE YOUR PATH - Continue to Web button clicked');
            } }, { children: "Open in Browser" })) }));
};
const ChooseYourPathView = (props) => {
    const { useOverrideLayoutProps } = props;
    useOverrideLayoutProps({
        maxContainerWidth: '100vw',
        showFooter: false,
    });
    const isMacClient = isMac();
    const isMobile = !useBreakpoint('md');
    const [activeSection, setActiveSection] = useState(SEGMENTS[0]);
    const sectionRef = useRef(null);
    // Make the section swipeable
    useDetectTouchSwipe(sectionRef, useCallback(movement => {
        if (!isMobile) {
            return;
        }
        if (movement === 'left-swipe') {
            setActiveSection(SEGMENTS[0]);
        }
        else {
            setActiveSection(SEGMENTS[1]);
        }
    }, [isMobile]));
    let content = null;
    if (isMobile) {
        content = (jsxs(Fragment, { children: [jsx(SegmentControl, { segments: SEGMENTS, activeSegment: activeSection, renderSegmentText: string => string, onSegmentClick: segment => {
                        setActiveSection(segment);
                    } }), jsx(Section, Object.assign({ "$isMobile": true, ref: sectionRef }, { children: activeSection === 'Design' ? (jsx(DesignOnYourMac, { isMacDesktop: false })) : (jsx(ExploreFromYourBrowser, {})) }))] }));
    }
    else {
        const sectionItems = [
            jsx(DesignOnYourMac, { isMacDesktop: isMacClient }, "mac"),
            jsx(ExploreFromYourBrowser, {}, "web"),
        ];
        const sectionContent = isMacClient ? sectionItems : sectionItems.reverse();
        content = jsx(Section, Object.assign({ ref: sectionRef }, { children: sectionContent }));
    }
    return (jsxs(IndexLayoutContent, Object.assign({ center: "horizontal", marginTop: true, paddingHorizontal: true }, { children: [jsxs(TitleWrapper, { children: [jsx(Text, Object.assign({ textStyle: "header.primary.I", as: SectionTitle }, { children: "Get the Best of Both Worlds" })), jsx(Text, Object.assign({ textStyle: "copy.quaternary.standard.F", as: SectionDescription }, { children: "Create beautiful designs with the award-winning macOS app and share them from your browser, on any platform." }))] }), content] })));
};
var ChooseYourPathView$1 = React.memo(ChooseYourPathView);

export { ChooseYourPathView$1 as default };

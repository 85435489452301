import { __awaiter } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import { useHistory, useLocation } from 'react-router-dom';
import { useSetUserAuthorization, useFetchInitialUser, routes, IndexLayoutContent, IndexLayoutHeaderLink, IndexLayoutOldTitle } from '@sketch/modules-common';
import { useToast } from '@sketch/toasts';
import { VerificationCodeInput, Link } from '@sketch/components';
import { useValidateMfaTotpMutation } from '@sketch/gql-types';
import { Wrapper, Subtitle, VerificationCodeContainer, LinkDescription } from './VerificationCodeView.styles.js';

const VerificationCodeView = (props) => {
    const { HeaderPortal } = props;
    const history = useHistory();
    const location = useLocation();
    const { showToast } = useToast();
    const setUserAuthorization = useSetUserAuthorization();
    const fetchInitialUser = useFetchInitialUser();
    const [validateOneTimePassword] = useValidateMfaTotpMutation({
        onCompleted: (data) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            // Save the credentials (Sign the user in)
            setUserAuthorization(data.validateMfaTotp.credentials);
            // Get the user profile for the first time now, since <UserContext /> (App.tsx)
            // can't do it because the user is not signed in until now
            yield fetchInitialUser();
            history.push(((_a = location.state) === null || _a === void 0 ? void 0 : _a.from) || routes.ENTRY.create({}));
        }),
        onError: error => {
            // TODO: Improve returned error types from local resolvers
            // https://github.com/sketch-hq/Cloud/issues/11366
            const mfaError = error.message;
            showToast(mfaError.message, 'negative');
            if (mfaError.type === 'invalid_mfa_token') {
                history.push(routes.SIGN_IN.create({}), undefined);
            }
        },
    });
    const handleSubmit = (totp) => {
        validateOneTimePassword({
            variables: {
                token: location.state.mfaToken,
                totp,
            },
        });
    };
    return (jsxs(IndexLayoutContent, Object.assign({ center: "horizontal", marginTop: true, paddingHorizontal: true }, { children: [jsx(HeaderPortal, { children: jsx(IndexLayoutHeaderLink, { headerLink: "back-sign-in" }) }), jsxs(Wrapper, { children: [jsx(IndexLayoutOldTitle, { children: "Enter Verification Code" }), jsx(Subtitle, { children: "Open your authenticator app and enter the code before it expires \u2014 or wait for a new one." }), jsx(VerificationCodeContainer, { children: jsx(VerificationCodeInput, { onFilled: handleSubmit }) }), jsxs(LinkDescription, { children: ["Can\u2019t access your authenticator app?", ' ', jsx(Link, Object.assign({ to: {
                                    pathname: routes.RECOVERY_CODE.create({}),
                                    state: { mfaToken: location.state.mfaToken },
                                }, variant: "tertiary", isUnderlined: true }, { children: "Use a recovery code" }))] }), jsxs(LinkDescription, { children: ["Lost your recovery codes?", ' ', jsx(Link, Object.assign({ external: true, variant: "tertiary", isUnderlined: true, href: "https://www.sketch.com/support/contact/" }, { children: "Contact us" }))] })] })] })));
};

export { VerificationCodeView as default };

import { useState, useEffect } from 'react';

/**
 * useScrollTop
 * Simple hook that returns the scrollTop value of an element
 */
const useScrollTop = (element) => {
    const [isScrollTop, setIsScrollTop] = useState(true);
    const htmlElement = element !== null && element !== void 0 ? element : window.document.body;
    useEffect(() => {
        const handleScroll = () => {
            const scrollTopValue = htmlElement.scrollTop;
            const isOnTop = scrollTopValue === 0;
            setIsScrollTop(isOnTop);
        };
        htmlElement.addEventListener('scroll', handleScroll);
        return () => {
            htmlElement.removeEventListener('scroll', handleScroll);
        };
    }, [htmlElement]);
    return {
        isScrollTop,
    };
};

export { useScrollTop };

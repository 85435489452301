import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { Navbar } from '@sketch/components';
import '../SketchHomeButton/index.js';
import '../../utils/index.js';
import { ReactComponent } from '@sketch/icons/cross-20';
import { useRefresh24 } from '../../utils/useRefresh24.js';
import { SketchHomeButton } from '../SketchHomeButton/SketchHomeButton.js';

const EmptyHeader = ({ onClickClose, title, hideLogo, isDocumentView, }) => {
    const isRefreshedUi = useRefresh24();
    return (jsxs(Navbar, Object.assign({ "$isRefreshedUi": isRefreshedUi, isDocumentView: isDocumentView }, { children: [jsxs(Navbar.Section, Object.assign({ flex: 0 }, { children: [onClickClose && (jsxs(Fragment, { children: [jsx(Navbar.Button, { icon: ReactComponent, onClick: onClickClose }), jsx(Navbar.Divider, {})] })), !onClickClose && !hideLogo && (jsxs(Fragment, { children: [jsx(SketchHomeButton, {}), jsx(Navbar.Divider, {})] }))] })), title && jsx(Navbar.Title, { children: title })] })));
};

export { EmptyHeader };

import React from 'react'
import jwtDecode from 'jwt-decode'
import { useHistory } from 'react-router'
import { Redirect } from 'react-router-dom'
import { captureMessage } from '@sentry/browser'

import { Flex, LoadingState } from '@sketch/components'
import AcceptMembershipPanel from 'modules/shares/AcceptShareInviteView/AcceptMembershipPanel'
import SwitchAccountOrAcceptMembershipPanel from 'modules/shares/AcceptShareInviteView/SwitchAccountOrAcceptMembershipPanel'
import {
  NotFoundView,
  IsSignedIn,
  IsNotSignedIn,
  InvalidInvitationView,
  routes,
  useQueryParams,
  Inviter,
  RouteProps,
  IndexLayoutExtraProps,
  IndexLayoutContent,
  useUserProfile,
  useSignOut,
} from '@sketch/modules-common'

import { useToast } from '@sketch/toasts'
import {
  useAcceptProjectMembershipInvitationMutation,
  useGetProjectMembershipByTokenQuery,
  useRequestAccessToProjectMutation,
} from '@sketch/gql-types'

interface InviteTokenData {
  project_identifier: string | null
  project_name: string | null
  project_membership_identifier: string | null
}

const getInviteTokenInfo = (inviteToken: string = ''): InviteTokenData => {
  try {
    return jwtDecode<InviteTokenData>(inviteToken)
  } catch {
    return {
      project_identifier: null,
      project_name: null,
      project_membership_identifier: null,
    }
  }
}

interface AcceptInviteProps {
  projectIdentifier: string
  projectName: string
  projectMembershipIdentifier: string
  workspaceIdentifier: string
  inviter: Inviter | null
  invitedEmail: string
  token: string
}

const AcceptInviteRoute = (props: AcceptInviteProps) => {
  const {
    projectIdentifier,
    projectName,
    projectMembershipIdentifier,
    workspaceIdentifier,
    inviter,
    invitedEmail,
    token,
  } = props
  const { data: profile } = useUserProfile()
  const { showToast } = useToast()
  const history = useHistory()

  const [
    acceptMembership,
    { loading: acceptMembershipLoading, error: acceptMembershipError },
  ] = useAcceptProjectMembershipInvitationMutation({
    variables: {
      input: {
        token,
        projectMembershipIdentifier,
      },
    },
    onCompleted: () => {
      showToast('Invite successfully accepted')
      history.push(
        routes.WORKSPACE_PROJECT.create({
          workspaceId: workspaceIdentifier,
          projectId: projectIdentifier,
        })
      )
    },
    onError: 'do-nothing', // we use acceptShareMembershipError to show the error message
  })

  const signOut = useSignOut({
    location: {
      pathname: routes.SIGN_IN.create({}),
      state: {
        from: history.location,
        projectInvitation: {
          inviter,
          projectName,
        },
      },
    },
    reason: 'Switching account to accept project invitation',
  })

  const [requestAccess] = useRequestAccessToProjectMutation({
    variables: { projectIdentifier },
    onError: 'show-toast',
    onCompleted() {
      showToast('Request has been sent')
      history.push(routes.ENTRY.create({}))
    },
  })

  const userEmail = profile?.me.email
  const userAvatar = profile?.me.avatar?.small
  const isSSOAccount = !profile?.me.hasPersonalIdentity
  const isCurrentUserInvited = invitedEmail === userEmail

  // Just to get rid of TS errors
  if (!userEmail) return null

  const componentProps = {
    resourceType: 'project' as const,
    resourceName: projectName,
    inviter,
    acceptMembership,
    acceptMembershipLoading,
    acceptMembershipError,
    userInfo: { userEmail, userAvatar },
  }

  if (isCurrentUserInvited && !isSSOAccount) {
    return <AcceptMembershipPanel {...componentProps} />
  }

  return (
    <SwitchAccountOrAcceptMembershipPanel
      {...componentProps}
      invitedEmail={invitedEmail}
      userInfo={{
        userEmail,
        userAvatar,
      }}
      onRequestAccess={requestAccess}
      onSwitchAccount={signOut}
    />
  )
}

export interface AcceptProjectInviteByTokenViewProps
  extends RouteProps<'PROJECT_ACCEPT_INVITE'>,
    IndexLayoutExtraProps {}

export const AcceptProjectInviteByTokenView = (
  props: AcceptProjectInviteByTokenViewProps
) => {
  const { token } = useQueryParams<'PROJECT_ACCEPT_INVITE'>()
  const { project_membership_identifier } = getInviteTokenInfo(token)

  if (!project_membership_identifier) {
    captureMessage('Failed to parse project invite token')
  }

  const { data, error, loading } = useGetProjectMembershipByTokenQuery({
    variables: { token: token ?? '' },
    skip: !token,
  })

  if (loading) {
    return <LoadingState />
  }

  if (!token || !data || error) {
    return <NotFoundView isInLayout />
  }

  const { projectMembership } = data
  if (!projectMembership) {
    return <InvalidInvitationView />
  }

  if (projectMembership.inviteStatus === 'ACCEPTED') {
    return (
      <Redirect
        to={routes.WORKSPACE_PROJECT.create({
          workspaceId: projectMembership.project.workspace.identifier,
          projectId: projectMembership.project.identifier,
        })}
      />
    )
  }

  return (
    <IndexLayoutContent center>
      <Flex
        maxWidth="512px"
        width="100%"
        alignSelf="center"
        flex="1"
        alignItems="center"
      >
        <IsSignedIn>
          <AcceptInviteRoute
            projectIdentifier={projectMembership.project.identifier}
            projectName={projectMembership.project.name}
            projectMembershipIdentifier={projectMembership.identifier}
            workspaceIdentifier={projectMembership.project.workspace.identifier}
            inviter={projectMembership.inviter}
            invitedEmail={projectMembership.email}
            token={token}
          />
        </IsSignedIn>
        <IsNotSignedIn>
          <Redirect
            to={{
              pathname: routes.SIGN_IN.template(),
              state: {
                from: routes.PROJECT_ACCEPT_INVITE.create({
                  query: { token },
                }),
                projectInvitation: {
                  inviter: projectMembership.inviter,
                  projectName: projectMembership.project.name,
                  projectMembershipIdentifier: projectMembership.identifier,
                  token,
                },
              },
            }}
          />
        </IsNotSignedIn>
      </Flex>
    </IndexLayoutContent>
  )
}
